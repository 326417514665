import { useEffect, useState } from "react";
import BaseViewModel from "../../base/BaseViewModel";
import Web3 from "web3";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  createCallbackLog,
  getFund,
  getOneRequest,
  sendCallback,
  updateOneRequest,
} from "../../utils/Apis";
import { useWalletState } from "../../utils/Hooks/useWalletState";
import { useWeb3Handler } from "../../utils/Hooks/useWeb3Handler";
import {requiredChainTip,getblockchain} from "../../utils/HelperFunctions";

const { ethereum } = window;
const web3 = new Web3(ethereum);

function useWithdrawFund() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    address,
    requestAccounts,
    isChainValid,
    requiredChain,
    currentChain,
  } = useWalletState();
  const { sendTransaction } = useWeb3Handler(web3);

  const [isLoading, setIsLoading] = useState(false);
  const [request, setRequest] = useState({});
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [fund, setFund] = useState(0);
  const [offering, setOffering] = useState({});
  const [contract, setContract] = useState({});
  const [source, setSource] = useState("");

  const fetchFund = async (id) => {
    try {
      const { message } = await getFund(address, id);
      const { fund: auctionFund } = message || {};
      setFund(auctionFund);
    } catch (e) {
      setFund(0);
    }
  };

  const fetchRequest = async () => {
    setIsLoading(true);
    try {
      const ref = searchParams.get("ref");
      if (!ref) {
        navigate("/error/?type=invalid-reference", {
          state: { source: Document.referer },
          replace: true,
        });
        return;
      }
      const { message } = await getOneRequest(ref);
      const {
        callbackUrl = "",
        status = "pending",
        _id: id,
        transactions = [],
        Offering = {},
        externalRef,
      } = message || {};
      if (status === "success")
        navigate("/success", { state: { source: externalRef }, replace: true });
      await fetchFund(Offering._id);
      const { MarketplaceContract } = Offering;
      setOffering(Offering);
      setRequest({
        callbackUrl,
        status,
        ref,
        id,
        transactions,
      });
      setContract(MarketplaceContract);
      setSource(externalRef);
    } catch (e) {
      console.error(e);
      navigate("/error", {
        state: { source: source || Document.referer },
        replace: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCallback = async (callbackData, requestData) => {
    setIsLoading(true);
    let status;
    try {
      await updateOneRequest(request.ref, requestData);
      status = "success";
      await sendCallback(request.callbackUrl, callbackData);
      await createCallbackLog({
        Request: request.id,
        status: "success",
        rawData: callbackData,
      });
    } catch (e) {
      await createCallbackLog({
        Request: request.id,
        status: "failed",
        rawData: callbackData,
      });
      console.error(`Failed to update offering: ${e.message}`);
    } finally {
      setIsLoading(false);
      if (requestData.status === "success" && status === "success")
        navigate("/success", { state: { source }, replace: true });
    }
  };

  const withdrawFund = async () => {
    const { ref: offeringId } = offering || {};
    return sendTransaction({
      contract,
      methodName: "withdrawAuctionBid",
      inputs: [offeringId],
      from: address,
      action: "WithdrawFund",
    });
  };

  const onConfirm = async () => {
    if (request.status === "success") return;

    try {
      setError(false);
      setIsLoading(true);

      const transactions = [...request.transactions];

      let txResult = await withdrawFund();

      transactions.push(txResult);

      if (!txResult.status) setError(true);

      const response = {
        status: txResult.status ? "success" : "failed",
        transactions,
      };

      await handleCallback(
        {
          ...response,
          ref: request.ref,
        },
        response
      );
    } catch (e) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseAlert = () => {
    setAlert({});
    setShowAlert(false);
  };

  useEffect(() => {
    if (!address) {
      setAlert({
        type: "error",
        title: "Error",
        message: `Please connect to your wallet`,
      });
      setShowAlert(true);
    }
  }, [address]);


  useEffect(() => {
    if (!isChainValid) {
      if(getblockchain()=='Polygon'){
        setShowAlert(false);
        requiredChainTip();
     }else{
      setAlert({
        type: "error",
        title: "Error",
        message: `You are using service provided on ${requiredChain}, but your wallet is connected to ${currentChain}.`,
      });
      setShowAlert(true);
     }
     
    }else{
      if(document.querySelector('.login_tip_dialog')){
        document.querySelector('.login_tip_dialog').remove();
      } 
    }
  }, [isChainValid]);

  useEffect(() => {
    fetchFund(offering._id);
  }, [address]);

  useEffect(() => {
    fetchRequest();
  }, []);

  return {
    isLoading,
    error,
    fund,
    alert,
    showAlert,
    address,
    isChainValid,

    onConfirm,
    onCloseAlert,
    requestAccounts,
  };
}

const WithdrawFundViewModel = BaseViewModel(useWithdrawFund);

export default WithdrawFundViewModel;
