import qs from "qs";

const DEFAULT_HEADER = {
  Accept: "application/json, text/plain, /",
  "Content-Type": "application/json",
};

const APIRequestGenerator = (rawURL, params = null, apiKey = null) => {
  const url = new URL(rawURL);
  if (params && typeof params === "object") {
    Object.assign(url, {
      search: qs.stringify(params, { arrayFormat: "brackets" }),
    });
  }
  const header = { ...DEFAULT_HEADER };
  if (apiKey) Object.assign(header, { "App-API-Key": apiKey });
  return { url: url.toString(), header };
};

export default APIRequestGenerator;
