import React from "react";
import Button from "../../../components/Button";
import { useSearchParams, useLocation } from "react-router-dom";
import "./style.css";
import Card from "../../../components/Card";
import logo from "../../../asset/logo.png";

const Success = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const type = searchParams.get("type");
  const { state } = location || {};
  const { source } = state || {};

  const onClickBtn = () => {
    if (source) window.location.replace(source);
    else window.close();
  };

  return (
    <main id={"success-page"} className={"wraps"}>
      <div className={"logo_imgs"}>
        <img src={logo} alt="QonBay.io" />
      </div>
      <Card>
        <h2>{type === "compCheck" ? "Congratulations!" : "Success!"}</h2>
        <p>
          {type === "compCheck"? "Your transaction has been processed. ": (type==='connectWallet'?'Your wallet has been connected.':" Your request has been handled. ")}
        </p>
        <Button id={"back-btn"} onClick={onClickBtn}>
          Back
        </Button>
      </Card>
    </main>
  );
};

export default Success;
