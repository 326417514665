const CHAIN = {
  "0x1": "Ethereum Main Network (Mainnet)",
  "0x3": "Ropsten Test Network",
  "0x4": "Rinkeby Test Network",
  "0x5": "Goerli Test Network",
  "0x2a": "Kovan Test Network",
  "0x13881":"Mumbai",
  "0x89": "Polygon Main Network (Mainnet)",
};

const CURRDATA = {
  "0x1": "ETH",
  "0x3": "ETH",
  "0x4": "ETH",
  "0x5": "ETH",
  "0x2a": "ETH",
  "0x13881":"MATIC",
  "0x89": "MATIC",
};
const NETPARAME = {
  "0x1":{
    "name":"Ethereum",
    "url":"",
  },
  "0x3": {
    "name":"Ethereum",
    "url":"",
  },
  "0x4": {
    "name":"Ethereum",
    "url":"",
  },
  "0x5": {
    "name":"Ethereum",
    "url":"",
  },
  "0x2a": {
    "name":"Ethereum",
    "url":"",
  },
  "0x13881":{
    "name":"Polygon",
    "url":'https://matic-mumbai.chainstacklabs.com',
  },
  "0x89": {
    "name":"Polygon",
    "url":'https://polygon-rpc.com',
  }
 
};
export { CHAIN,CURRDATA,NETPARAME};
