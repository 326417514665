import React from "react";
import Spinner from "../../Spinner";
import "./style.css";

const Processing = () => {
  return (
    <div id={"processing"}>
      <Spinner />
      <span>Processing...</span>
    </div>
  );
};

export default Processing;
