import Web3 from "web3";
import { useEffect, useState } from "react";
import { CHAIN,CURRDATA} from "../constants";
import { useNavigate } from "react-router-dom";
import detectEthereumProvider from '@metamask/detect-provider';

let web3;
let { ethereum } = window;
const REQUIRED_CHAIN_ID = `0x${parseInt(
  process.env.REACT_APP_CHAIN_ID,
  10
).toString(16)}`;
const REQUIRED_CHAIN = CHAIN[REQUIRED_CHAIN_ID];
const SELECT_CURR = CURRDATA[REQUIRED_CHAIN_ID];
const useWalletState = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState(ethereum?.selectedAddress || "");
  const [isChainValid, setIsChainValid] = useState(true);
  const [currentChain, setCurrentChain] = useState("");
  
  const isMetamaskInstalled = async () => {
    const provider = await detectEthereumProvider();
    if (provider && typeof ethereum !== "undefined") {
      web3 = new Web3(ethereum);
      return true;
    } else {
      return false;
    }
  };

  const validateChain = async (chainId) => {
    let currentChainId =
      chainId || `0x${(await web3.eth.getChainId()).toString(16)}`;
    const chain = CHAIN[currentChainId];
    setCurrentChain(chain);
    if (currentChainId === REQUIRED_CHAIN_ID) {
      setIsChainValid(true);
    } else {
      setIsChainValid(false);
    }
  };

  const requestAccounts = async () => {
    try {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      if (!Array.isArray(accounts) || accounts.length < 1) {
        setAddress("");
      } else {
        setAddress(accounts[0]);
      }
    } catch (e) {
      if (e.code === -32002) {
        window.alert(
          "Request accounts failed. Please check if you have unlocked the wallet."
        );
      }
    }
  };

  const initialize = async () => {
    const isMetaMaskReady = await isMetamaskInstalled();
    if (isMetaMaskReady) {
      validateChain();
      ethereum.on("accountsChanged", function (accounts) {
        if (!Array.isArray(accounts) || accounts.length < 1) {
          setAddress("");
        } else {
          setAddress(accounts[0]);
        }
      });
      ethereum.on("chainChanged", async (chainId) => {
        await validateChain(chainId);
      });
    } else {
      window.alert("Please install Metamask");
      navigate("/error/?type=missing-provider");
    }
    return () => {
      if (ethereum) {
        ethereum.removeListener("accountsChanged", () => {
          setAddress("");
        });
        ethereum.removeListener("chainChanged", () => {
          setAddress("");
        });
      }
    };
  }
  useEffect(() => {
    initialize();
  }, []);
  return {
    address,
    isChainValid,
    currentChain,
    requiredChain: REQUIRED_CHAIN,
    currentCurr:SELECT_CURR?SELECT_CURR:'ETH',
    isMetamaskInstalled,
    requestAccounts,
  };
};

export { useWalletState };
