import React from "react";
import "./style.css";

const Failed = () => {
  return (
    <div id={"failed"}>
      <div>&#10007;</div>
      <span>Failed</span>
    </div>
  );
};

export default Failed;
