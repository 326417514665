import axios from "axios";

function APIHelper(method, url, data, isJSON = true, headers) {
  return axios({
    url,
    method,
    headers,
    data: isJSON ? JSON.stringify(data) : data,
  });
}

export default APIHelper;
