import React, { useContext } from "react";
import WalletViewModel from "./viewModel";
import ConnectWallet from "./containers/ConnectWallet";
import ChangeWallet from "./containers/ChangeWallet";
import DisconnectWallet from "./containers/DisconnectWallet";
import logo from '../../asset/logo.png'
import "./style.css";
import { Toast, ToastContainer } from "react-bootstrap";

const Wallet = () => {
  const { request, alert, showAlert, onCloseAlert } = useContext(
    WalletViewModel.Context
  );
  const { action = "" } = request || {};

  return (
    <main id={"wallet-page"} className={'wraps'}>
      <ToastContainer position="top-end" className="p-3">
        {showAlert && action && action !== "disconnect" && (
          <Toast onClose={() => onCloseAlert()} delay={5000} autohide>
            <Toast.Header>
              <strong className="me-auto">{alert.title}</strong>
            </Toast.Header>
            <Toast.Body>{alert.message}</Toast.Body>
          </Toast>
        )}
      </ToastContainer>
      <div className={'logo_imgs'}>
          <img src={logo} alt="QonBay.io"/>
      </div>
      {action === "connect" && <ConnectWallet />}
      {action === "change" && <ChangeWallet />}
      {action === "disconnect" && <DisconnectWallet />}
    </main>
  );
};

export default WalletViewModel.withProvider(Wallet);
