import APIRequestGenerator from "../base/APIRequestGenerator";
import APIHelper from "../base/APIHelper";

const HOST = process.env.REACT_APP_HOST;
const API_KEY = process.env.REACT_APP_API_KEY;
const APP_SMARTCONTRACT = process.env.REACT_APP_SMARTCONTRACT;
async function sendCallback(rawUrl, data) {
  try {
    const { url, header } = APIRequestGenerator(rawUrl);
    const { data: response } = await APIHelper(
      "POST",
      url.toString(),
      data,
      true,
      header
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getOneRequest(ref) {
  try {
    const { url, header } = APIRequestGenerator(
      `${HOST}/request/${ref}`,
      null,
      API_KEY
    );
    const { data: response } = await APIHelper(
      "GET",
      url.toString(),
      null,
      true,
      header
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function updateOneRequest(ref, data) {
  try {
    const { url, header } = APIRequestGenerator(
      `${HOST}/request/${ref}`,
      null,
      API_KEY
    );
    const { data: response } = await APIHelper(
      "PUT",
      url.toString(),
      data,
      true,
      header
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getSignedOfferingTx(data) {
  try {
    const { url, header } = APIRequestGenerator(
      `${HOST}/sign-offering`,
      null,
      API_KEY
    );
    const { data: response } = await APIHelper(
      "POST",
      url.toString(),
      data,
      true,
      header
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function createCallbackLog(data) {
  try {
    const { url, header } = APIRequestGenerator(
      `${HOST}/callback-log`,
      null,
      API_KEY
    );
    const { data: response } = await APIHelper(
      "POST",
      url.toString(),
      data,
      true,
      header
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getBalance(address) {
  try {
    const { url, header } = APIRequestGenerator(
      `${HOST}/balance`,
      { address,smartContract:APP_SMARTCONTRACT},
      API_KEY
    );
    const { data: response } = await APIHelper(
      "GET",
      url.toString(),
      null,
      true,
      header
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function createOffering(data) {
  try {
    const { url, header } = APIRequestGenerator(
      `${HOST}/offering`,
      null,
      API_KEY
    );
    const { data: response } = await APIHelper(
      "POST",
      url.toString(),
      data,
      true,
      header
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function updateOffering(id, data) {
  try {
    const { url, header } = APIRequestGenerator(
      `${HOST}/offering/${id}`,
      null,
      API_KEY
    );
    const { data: response } = await APIHelper(
      "PUT",
      url.toString(),
      data,
      true,
      header
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getFund(address, offeringId) {
  try {
    const { url, header } = APIRequestGenerator(
      `${HOST}/fund`,
      { address, offeringId },
      API_KEY
    );
    const { data: response } = await APIHelper(
      "GET",
      url.toString(),
      null,
      true,
      header
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}

export {
  getOneRequest,
  updateOneRequest,
  sendCallback,
  getSignedOfferingTx,
  createCallbackLog,
  getBalance,
  createOffering,
  updateOffering,
  getFund,
};
