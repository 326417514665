import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router-dom";
import Wallet from "./containers/Wallet";
import PlaceOffering from "./containers/PlaceOffering";
import SignOffering from "./containers/SignOffering";
import CloseOffering from "./containers/CloseOffering";
import CancelOffering from "./containers/CancelOffering";
import WithdrawBalance from "./containers/WithdrawBalance";
import Error from "./containers/FallbackPages/Error";
import Success from "./containers/FallbackPages/Success";
import NotFound from "./containers/FallbackPages/NotFound";
import Mint from "./containers/Mint";
import PlaceBid from "./containers/PlaceBid";
import CancelBid from "./containers/CancelBid";
import WithdrawFund from "./containers/WithdrawFund";

function App() {
  return (
    <Routes>
      <Route path="/wallet" element={<Wallet />} />
      <Route path="/place-offering" element={<PlaceOffering />} />
      <Route path="/sign-offering" element={<SignOffering />} />
      <Route path="/close-offering" element={<CloseOffering />} />
      <Route path="/cancel-offering" element={<CancelOffering />} />
      <Route path="/withdraw-balance" element={<WithdrawBalance />} />
      <Route path="/mint" element={<Mint />} />
      <Route path="/place-bid" element={<PlaceBid />} />
      <Route path="/cancel-bid" element={<CancelBid />} />
      <Route path="/withdraw-fund" element={<WithdrawFund />} />
      <Route path="/success" element={<Success />} />
      <Route path="/error" element={<Error />}>
        <Route path="/error/?type=invalid-reference" element={<Error />} />
        <Route path="/error/?type=missing-provider" element={<Error />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
