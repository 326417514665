import React from "react";
import { PROGRESS_FLOW } from "../constants";
import "./style.css";
import Spinner from "../../../components/Spinner";

const Progress = (props) => {
  const { current, id, loading, error, title, label } = props;
  const currentIndex = PROGRESS_FLOW.indexOf(current);
  const index = PROGRESS_FLOW.indexOf(id);
  const processed = currentIndex > index && !loading;
  const currentError = error && current === id;

  return (
    <div className={"progress-item"}>
      {loading && <Spinner />}
      {(!processed || loading) && !currentError && (
        <div className={"label"}>{label}</div>
      )}
      {processed && <div className={"label checked"}>&#10003;</div>}
      {currentError && <div className={"label error"}>&#10007;</div>}
      <span className={"title"}>{title}</span>
    </div>
  );
};

export default Progress;
