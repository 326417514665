import { useEffect, useState } from "react";
import BaseViewModel from "../../base/BaseViewModel";
import Web3 from "web3";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  createCallbackLog,
  getOneRequest,
  sendCallback,
  updateOneRequest,
} from "../../utils/Apis";
import { useWalletState } from "../../utils/Hooks/useWalletState";
import { useWeb3Handler } from "../../utils/Hooks/useWeb3Handler";
import {requiredChainTip,getblockchain} from "../../utils/HelperFunctions";

const { ethereum } = window;
const web3 = new Web3(ethereum);

function useMint() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { address, requestAccounts, isChainValid, requiredChain } =
    useWalletState();
  const { sendTransaction } = useWeb3Handler(web3);

  const [isLoading, setIsLoading] = useState(false);
  const [request, setRequest] = useState({});
  const [error, setError] = useState(false);
  const [token, setToken] = useState({});
  const [alert, setAlert] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [source, setSource] = useState("");
  const [contract, setContract] = useState({});

  const fetchRequest = async () => {
    setIsLoading(true);
    try {
      const ref = searchParams.get("ref");
      if (!ref) {
        navigate("/error/?type=invalid-reference", { replace: true });
        return;
      }
      const { message } = await getOneRequest(ref);
      const {
        callbackUrl = "",
        status = "pending",
        token: originalToken = {},
        _id: id,
        transactions,
        externalRef,
        SmartContract = {},
      } = message || {};
      if (status === "success")
        navigate("/success?type=compCheck", {
          state: { source: externalRef },
          replace: true,
        });
      setSource(externalRef);
      setToken(originalToken);
      setRequest({
        callbackUrl,
        status,
        ref,
        id,
        transactions,
      });
      setContract(SmartContract);
    } catch (e) {
      console.error(e);
      navigate("/error", { state: { source }, replace: true });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCallback = async (callbackData, requestData) => {
    setIsLoading(true);
    let status;
    try {
      await updateOneRequest(request.ref, requestData);
      status = "success";
      await sendCallback(request.callbackUrl, callbackData);
      await createCallbackLog({
        Request: request.id,
        status: "success",
        rawData: callbackData,
      });
    } catch (e) {
      await createCallbackLog({
        Request: request.id,
        status: "failed",
        rawData: callbackData,
      });
      console.error(`Failed to update offering: ${e.message}`);
    } finally {
      setIsLoading(false);
      if (requestData.status === "success" && status === "success")
        navigate("/success?type=compCheck", {
          state: { source },
          replace: true,
        });
    }
  };

  const mintERC721 = async () => {
    const {
      owner = "",
      tokenId = null,
      uri = "",
      unboxDate = 0,
      blindBoxUri = "",
      royaltyBasisPoint = 0,
    } = token || {};
    return sendTransaction({
      contract,
      methodName: "mint",
      inputs: [owner, tokenId, uri, unboxDate, blindBoxUri, royaltyBasisPoint],
      from: address,
      action: "Mint",
    });
  };

  const mintERC1155 = async () => {
    const {
      owner = "",
      tokenId = null,
      uri = "",
      unboxDate = 0,
      blindBoxUri = "",
      royaltyBasisPoint = 0,
      royaltyReceiver = "",
      supply = 1,
      quantity = 1,
    } = token || {};

    return sendTransaction({
      contract,
      methodName: "mint",
      inputs: [
        owner,
        tokenId,
        uri,
        unboxDate,
        blindBoxUri,
        royaltyBasisPoint,
        royaltyReceiver,
        supply,
        quantity,
      ],
      from: address,
      action: "Mint",
    });
  };

  const mint = async () => {
    if (contract.type === "ERC1155") return await mintERC1155();
    return await mintERC721();
  };

  const onConfirm = async () => {
    try {
      if (request.status === "success") return;

      setError(false);
      setIsLoading(true);

      const transactions = [...request.transactions];

      let txResult = await mint();

      transactions.push(txResult);

      if (!txResult.status) setError(true);

      const response = {
        status: txResult.status ? "success" : "failed",
        transactions,
      };

      await handleCallback(
        {
          ...response,
          ref: request.ref,
        },
        response
      );
    } catch (e) {
      setError(true);
      window.alert(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseAlert = () => {
    setAlert({});
    setShowAlert(false);
  };

  useEffect(() => {
    if (!address) {
      setAlert({
        type: "error",
        title: "Message",
        message: `Please connect to your wallet`,
      });
      setShowAlert(true);
    }
  }, [address]);

  useEffect(() => {
    if (!isChainValid) {
      if(getblockchain()=='Polygon'){
        setShowAlert(false);
        requiredChainTip();
     }else{
       setAlert({
        type: "error",
        title: `Please switch to  ${requiredChain}`,
        message: `In order to trade items, please switch to ${requiredChain} within your MetaMask wallet.`,
      });
      setShowAlert(true);
     }
     
    }else{
      if(document.querySelector('.login_tip_dialog')){
        document.querySelector('.login_tip_dialog').remove();
      } 
    }
  }, [isChainValid]);

  useEffect(() => {
    fetchRequest();
  }, []);

  return {
    isLoading,
    error,
    alert,
    showAlert,
    address,
    isChainValid,

    onConfirm,
    requestAccounts,
    onCloseAlert,
  };
}

const MintViewModel = BaseViewModel(useMint);

export default MintViewModel;
