import { useEffect, useState } from "react";
import BaseViewModel from "../../base/BaseViewModel";
import Web3 from "web3";
import { etherToHex,requiredChainTip,getblockchain} from "../../utils/HelperFunctions";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  createCallbackLog,
  getOneRequest,
  sendCallback,
  updateOffering,
  updateOneRequest,
} from "../../utils/Apis";
import { useWalletState } from "../../utils/Hooks/useWalletState";
import { useWeb3Handler } from "../../utils/Hooks/useWeb3Handler";

const { ethereum } = window;
const web3 = new Web3(ethereum);

function useCloseOffering() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { address, requestAccounts, isChainValid, requiredChain } =
    useWalletState();
  const { sendTransaction } = useWeb3Handler(web3);

  const [isLoading, setIsLoading] = useState(false);
  const [request, setRequest] = useState({});
  const [error, setError] = useState(false);
  const [offering, setOffering] = useState({});
  const [alert, setAlert] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  //是否同意條款
  const [isAgreeTerm, setIsAgreeTerm] = useState(false);
  const [source, setSource] = useState("");
  const [contract, setContract] = useState({});

  const fetchRequest = async () => {
    setIsLoading(true);
    try {
      const ref = searchParams.get("ref");
      if (!ref) {
        navigate("/error/?type=invalid-reference", { replace: true });
        return;
      }
      const { message } = await getOneRequest(ref);
      const {
        callbackUrl = "",
        status = "pending",
        Offering = {},
        price,
        _id: id,
        transactions,
        externalRef,
        quantity,
        address: buyerAddress,
      } = message || {};
      if (status === "success")
        navigate("/success?type=compCheck", {
          state: { source: externalRef },
          replace: true,
        });
      setSource(externalRef);
      if (!(Offering.status === "pending" || Offering.status === "active")) {
        navigate("/error?tips=Invalid offering.", {
          state: { source },
          replace: true,
        });
        return;
      }
      const { MarketplaceContract } = Offering || {};
      setOffering(Offering);
      setContract(MarketplaceContract);
      setRequest({
        callbackUrl,
        status,
        ref,
        id,
        transactions,
        price,
        quantity,
        address: buyerAddress,
      });
    } catch (e) {
      console.error(e);
      navigate("/error", { state: { source }, replace: true });
    } finally {
      setIsLoading(false);
    }
  };

  const handleOffering = async () => {
    const { contractType, remaining = 1, type, _id, minted } = offering;
    const { quantity = 1 } = request;
    if (!(contractType === "ERC1155" && type === "fixed" && !minted)) {
      await updateOffering(_id, { status: "closed" });
      return;
    }
    const newRemaining = remaining - quantity;
    await updateOffering(_id, {
      status: newRemaining === 0 ? "closed" : "active",
      remaining: newRemaining,
    });
  };

  const handleCallback = async (callbackData, requestData) => {
    let status;
    try {
      await updateOneRequest(request.ref, requestData);
      status = "success";
      if (requestData.status === "success") await handleOffering();
      await sendCallback(request.callbackUrl, callbackData);
      await createCallbackLog({
        Request: request.id,
        status: "success",
        rawData: callbackData,
      });
    } catch (e) {
      await createCallbackLog({
        Request: request.id,
        status: "failed",
        rawData: callbackData,
      });
      console.error(`Failed to update offering: ${e.message}`);
    } finally {
      if (requestData.status === "success" && status === "success")
        navigate("/success?type=compCheck", {
          state: { source },
          replace: true,
        });
    }
  };

  const redeemERC721Voucher = async () => {
    const { details: voucher, ref: signature } = offering || {};
    const { price } = request || {};
    return sendTransaction({
      contract,
      methodName: "redeem",
      inputs: [voucher, signature],
      from: address,
      action: "Redeem",
      value: etherToHex(price),
    });
  };

  const redeemERC1155Voucher = async () => {
    const { details: voucher, ref: signature } = offering || {};
    const { price } = request || {};
    return sendTransaction({
      contract,
      methodName: "redeemERC1155",
      inputs: [voucher, signature, request.quantity],
      from: address,
      action: "Redeem",
      value: etherToHex(price),
    });
  };

  const redeemVoucher = async (contractType) => {
    if (contractType === "ERC721") return await redeemERC721Voucher();
    if (contractType === "ERC1155") return await redeemERC1155Voucher();
    throw new Error(`Invalid contract type: ${contractType}`);
  };

  const closeFixedPriceOffering = async () => {
    const { ref: offeringId } = offering || {};
    const { price } = request || {};
    return sendTransaction({
      contract,
      methodName: "closeFixedPriceOffering",
      inputs: [offeringId],
      from: address,
      action: "CloseFixedPriceOffering",
      value: etherToHex(price),
    });
  };

  const closeAuctionOffering = async () => {
    const { ref: offeringId } = offering || {};
    const { price } = request || {};
    return sendTransaction({
      contract,
      methodName: "closeAuctionOffering",
      inputs: [offeringId],
      from: address,
      action: "CloseAuctionOffering",
      value: etherToHex(price),
    });
  };

  const onConfirm = async () => {
    try {
      if (request.status === "success") return;

      if (!isAgreeTerm) {
        setAlert({
          type: "error",
          title: "Tips",
          message: "Please read and accept the Terms of Service",
        });
        setShowAlert(true);
        return;
      }

      setError(false);
      setIsLoading(true);

      const transactions = [...request.transactions];
      let txResult;

      const { type, minted, details, contractType } = offering || {};
      const { seller = "", owner = "" } = details || {};
      const { address: buyerAddress = "" } = request || {};

      if (type === "fixed") {
        if (address?.toLowerCase() !== buyerAddress.toLowerCase()) {
          setAlert({
            type: "error",
            title: "Message",
            message: "Buyer wallet address not match",
          });
          setShowAlert(true);
          return;
        }
      }

      if (type === "fixed" && minted === false) {
        if (owner.toLowerCase() === address?.toLowerCase()) {
          setAlert({
            type: "error",
            title: "Message",
            message: "You cannot buy your own products",
          });
          setShowAlert(true);
          return;
        }
        txResult = await redeemVoucher(contractType);
      }

      if (type === "fixed" && minted === true) {
        if (seller.toLowerCase() === address?.toLowerCase()) {
          setAlert({
            type: "error",
            title: "Message",
            message: "You cannot buy your own products",
          });
          setShowAlert(true);
          return;
        }
        txResult = await closeFixedPriceOffering();
      }

      if (type === "auction") {
        if (seller.toLowerCase() !== address?.toLowerCase()) {
          setAlert({
            type: "error",
            title: "Message",
            message: "Only seller can accept bid",
          });
          setShowAlert(true);
          return;
        }
        txResult = await closeAuctionOffering();
      }

      transactions.push(txResult);

      if (!txResult.status) setError(true);

      const response = {
        status: txResult.status ? "success" : "failed",
        transactions,
      };

      await handleCallback(
        {
          ...response,
          ref: request.ref,
        },
        response
      );
    } catch (e) {
      setError(true);
      window.alert(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  //處理點擊checkbox
  const handleCheckTerm = (e) => {
    setIsAgreeTerm(e.target.checked);
  };

  const onCloseAlert = () => {
    setAlert({});
    setShowAlert(false);
  };

  useEffect(() => {
  
    if (!address) {
      setAlert({
        type: "error",
        title: "Message",
        message: `Please connect to your wallet`,
      });
      setShowAlert(true);     
    }
  }, [address]);

  useEffect(() => {
    if (!isChainValid) {
      if(getblockchain()=='Polygon'){
         setShowAlert(false);
         requiredChainTip();
      }else{
        setAlert({
          type: "error",
          title: `Please switch to  ${requiredChain}`,
          message: `In order to trade items, please switch to ${requiredChain} within your MetaMask wallet.`,
        });
        setShowAlert(true);
      }
     

    }else{
      if(document.querySelector('.login_tip_dialog')){
        document.querySelector('.login_tip_dialog').remove();
      } 
    }
  }, [isChainValid]);

  useEffect(() => {
    fetchRequest();
  }, []);

  return {
    isLoading,
    error,
    alert,
    showAlert,
    address,
    isChainValid,
    offering,

    handleCheckTerm,
    onConfirm,
    requestAccounts,
    onCloseAlert,
  };
}

const CloseOfferingViewModel = BaseViewModel(useCloseOffering);

export default CloseOfferingViewModel;
