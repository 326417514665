import { useEffect, useState } from "react";
import BaseViewModel from "../../base/BaseViewModel";
import Web3 from "web3";
import Abis from "../../utils/Abis";
import { getTransactionResult,requiredChainTip,getblockchain} from "../../utils/HelperFunctions";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  createCallbackLog,
  getBalance,
  getOneRequest,
  sendCallback,
  updateOneRequest,
} from "../../utils/Apis";
import { useWalletState } from "../../utils/Hooks/useWalletState";

const { ethereum } = window;
const web3 = new Web3(ethereum);

function useWithdrawBalance() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    address,
    requestAccounts,
    isChainValid,
    requiredChain,
    currentChain,
    currentCurr,
  } = useWalletState();

  const [isLoading, setIsLoading] = useState(false);
  const [request, setRequest] = useState({});
  const [error, setError] = useState(false);
  const [balance, setBalance] = useState(0);
  const [alert, setAlert] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [source, setSource] = useState("");
  const [breakdowns, setBreakdowns] = useState([]);
  const [hasWithdrawn, setHasWithdrawn] = useState(false);
  const [handledContracts, setHandledContracts] = useState([]);

  const fetchRequest = async () => {
    setIsLoading(true);
    try {
      const ref = searchParams.get("ref");
      if (!ref) {
        navigate("/error/?type=invalid-reference", {
          state: { source: Document.referer },
          replace: true,
        });
        return;
      }
      const { message } = await getOneRequest(ref);
      const {
        callbackUrl = "",
        status = "pending",
        _id: id,
        transactions = [],
        externalRef,
      } = message || {};
      if (status === "success")
        navigate("/success", { state: { source: externalRef }, replace: true });
      setSource(externalRef);
      setRequest({
        callbackUrl,
        status,
        ref,
        id,
        transactions,
      });
    } catch (e) {
      console.error(e);
      navigate("/error", {
        state: { source: source || Document.referer },
        replace: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBalance = async () => {
    try {
      if (!address) throw new Error("Cannot read wallet address");
      const { message } = await getBalance(address);
      const { total = 0, breakdowns = [] } = message || {};
      setBalance(total);
      setBreakdowns(breakdowns);
    } catch (e) {
      setBalance(0);
      setBreakdowns([]);
    }
  };

  const handleCallback = async (callbackData, requestData) => {
    setIsLoading(true);
    try {
      await updateOneRequest(request.ref, requestData);
      await sendCallback(request.callbackUrl, callbackData);
      await createCallbackLog({
        Request: request.id,
        status: "success",
        rawData: callbackData,
      });
    } catch (e) {
      await createCallbackLog({
        Request: request.id,
        status: "failed",
        rawData: callbackData,
      });
      console.error(`Failed to update offering: ${e.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const onDone = () => {
    navigate("/success", { state: { source }, replace: true });
  };

  const withdrawBalance = async (contractAddress) => {
    const SmartContract = new web3.eth.Contract(
      [Abis.WithdrawBalance],
      contractAddress
    );

    const { transactionHash: hash } = await SmartContract.methods
      .withdrawBalance()
      .send({
        from: address,
      });

    return getTransactionResult(hash, "WithdrawBalance");
  };

  const onConfirm = async (contract) => {
    try {
      if (!contract) throw new Error("Invalid contract address");

      setError(false);
      setIsLoading(true);

      const transactions = [...request.transactions];

      let withdrawBalanceTxResult = await withdrawBalance(contract);
      transactions.push(withdrawBalanceTxResult);

      if (withdrawBalanceTxResult.status) {
        setHasWithdrawn(true);
        setHandledContracts((prev) => [...prev, contract]);
      } else setError(true);

      const response = {
        status: withdrawBalanceTxResult.status ? "success" : "failed",
        transactions,
      };

      await handleCallback(
        {
          ...response,
          ref: request.ref,
        },
        response
      );
    } catch (e) {
      setError(true);
      window.alert(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseAlert = () => {
    setAlert({});
    setShowAlert(false);
  };

  useEffect(() => {
    if (!address) {
      setAlert({
        type: "error",
        title: "Message",
        message: `Please connect to your wallet`,
      });
      setShowAlert(true);
    }
  }, [address]);


  useEffect(() => {
    if (!isChainValid) {
      if(getblockchain()=='Polygon'){
        setShowAlert(false);
        requiredChainTip();
     }else{
      setAlert({
        type: "error",
        title: "Message",
        message: `You are using service provided on ${requiredChain}, but your wallet is connected to ${currentChain}.`,
      });
      setShowAlert(true);
     }
     
    }else{
      if(document.querySelector('.login_tip_dialog')){
        document.querySelector('.login_tip_dialog').remove();
      } 
    }
  }, [isChainValid]);

  useEffect(() => {
    fetchRequest();
    fetchBalance();
  }, []);

  useEffect(() => {
    fetchBalance();
  }, [address]);

  return {
    isLoading,
    error,
    balance,
    alert,
    showAlert,
    address,
    isChainValid,
    breakdowns,
    hasWithdrawn,
    handledContracts,
    currentCurr,
    onConfirm,
    onCloseAlert,
    requestAccounts,
    onDone,
  };
}

const WithdrawBalanceViewModel = BaseViewModel(useWithdrawBalance);

export default WithdrawBalanceViewModel;
