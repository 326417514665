import React, { useContext } from "react";
import PlaceOfferingViewModel from "./viewModel";
import Card from "../../components/Card";
import Progress from "./components/Progress";
import "./style.css";
import { PROGRESS } from "./constants";
import Button from "../../components/Button";
import logo from "../../asset/logo.png";
import { Toast, ToastContainer } from "react-bootstrap";

const PlaceOffering = () => {
  const {
    onConfirm,
    isLoading,
    progress,
    error,
    alert,
    showAlert,
    onCloseAlert,
    address,
    isChainValid,
    requestAccounts,
  } = useContext(PlaceOfferingViewModel.Context);
  return (
    <main id={"place-offering"} className={"wraps"}>
      <ToastContainer position="top-end" className="p-3">
        {showAlert && (
          <Toast onClose={() => onCloseAlert()} delay={5000} autohide>
            <Toast.Header>
              <strong className="me-auto">{alert.title}</strong>
            </Toast.Header>
            <Toast.Body>{alert.message}</Toast.Body>
          </Toast>
        )}
      </ToastContainer>
      <div className={"logo_imgs"}>
        <img src={logo} alt="QonBay.io" />
      </div>
      <Card>
        <h2>Place Offering</h2>
        {!address && (
          <Button
            variant={"secondary"}
            onClick={() => requestAccounts()}
            disabled={!isChainValid}
            style={{ marginBottom: "1rem" }}
          >
            Connect Wallet
          </Button>
        )}
        <div className={"progress-wrapper"}>
          <Progress
            label={1}
            current={progress}
            id={PROGRESS.TO_APPROVE}
            loading={progress === PROGRESS.APPROVING}
            error={error}
            title={"Approve item for sale"}
          />
          <Progress
            label={2}
            current={progress}
            id={PROGRESS.TO_PLACE}
            loading={progress === PROGRESS.PLACING}
            error={error}
            title={"Place item to marketplace"}
          />
        </div>
        <Button
          onClick={() => onConfirm()}
          className={"default-btn confirm-btn"}
          disabled={isLoading || !address || !isChainValid}
        >
          Confirm
        </Button>
      </Card>
    </main>
  );
};

export default PlaceOfferingViewModel.withProvider(PlaceOffering);
