import React, { useContext } from "react";
import WalletViewModel from "../viewModel";
import Button from "../../../components/Button";
import Card from "../../../components/Card";

const DisconnectWallet = () => {
  const { onConfirm } = useContext(WalletViewModel.Context);
  return (
    <Card>
      <h2>Unlink Wallet Address?</h2>
      <Button
        onClick={() => onConfirm("")}
        className={"default-btn confirm-btn"}
      >
        Confirm
      </Button>
    </Card>
  );
};

export default DisconnectWallet;
