import React, { useContext } from "react";
import CancelBidViewModel from "./viewModel";
import Card from "../../components/Card";
import Processing from "../../components/Status/Processing";
import Failed from "../../components/Status/Failed";
import Button from "../../components/Button";
import "./style.css";
import { Toast, ToastContainer } from "react-bootstrap";

const CancelBid = () => {
  const {
    onConfirm,
    isLoading,
    error,
    alert,
    showAlert,
    onCloseAlert,
    address,
    isChainValid,
    requestAccounts,
    fund,
  } = useContext(CancelBidViewModel.Context);
  return (
    <main id={"withdraw-balance"}>
      <ToastContainer position="top-end" className="p-3">
        {showAlert && (
          <Toast onClose={() => onCloseAlert()} delay={5000} autohide>
            <Toast.Header>
              <strong className="me-auto">{alert.title}</strong>
            </Toast.Header>
            <Toast.Body>{alert.message}</Toast.Body>
          </Toast>
        )}
      </ToastContainer>
      <Card>
        <h2>Cancel Bid</h2>
        {!address && (
          <Button
            variant={"secondary"}
            onClick={() => requestAccounts()}
            disabled={!isChainValid}
            style={{ marginBottom: "1rem" }}
          >
            Connect Wallet
          </Button>
        )}
        {isLoading && <Processing />}
        {!isLoading && error && <Failed />}
        <div className={"balance-wrapper"}>
          Fund to be withdrawn:
          <span className={"balance"}>{`${fund} ETH`}</span>
          <span className={"address"}>{`(Address: ${address || "---"})`}</span>
        </div>
        <Button
          onClick={() => onConfirm()}
          disabled={isLoading || !address || !isChainValid || fund === "0"}
          className={"default-btn confirm-btn"}
        >
          Confirm
        </Button>
      </Card>
    </main>
  );
};

export default CancelBidViewModel.withProvider(CancelBid);
