import React from "react";
import "./style.css";

const ErrorList = (isAuction = false) => {
  return (
    <div className={"error-list"}>
      <p>Possible reasons:</p>
      <ul>
        <li>Incorrect network/chain</li>
        <li>Not connected to wallet</li>
        <li>Insufficient balance in wallet</li>
        <li>Non-authorized wallet</li>
        {isAuction && <li>The auction has not started</li>}
        {isAuction && <li>The auction has ended</li>}
      </ul>
    </div>
  );
};

export default ErrorList;
