import { getTransactionResult } from "../HelperFunctions";

const useWeb3Handler = (web3) => {
  const sendTransaction = async ({
    contract,
    methodName,
    inputs,
    from,
    value = 0,
    action = "",
  }) => {
    const SmartContract = new web3.eth.Contract(contract.abi, contract.address);

    const { transactionHash: hash } = await SmartContract.methods[methodName](
      ...inputs
    ).send({ from, value });

    return getTransactionResult(hash, action);
  };

  const callMethod = async ({ contract, methodName, inputs }) => {
    const SmartContract = new web3.eth.Contract(contract.abi, contract.address);
    return await SmartContract.methods[methodName](...inputs).call();
  };

  return {
    sendTransaction,
    callMethod,
  };
};

export { useWeb3Handler };
