const PROGRESS = {
  TO_MINT: "to-mint",
  MINTING: "minting",
  TO_APPROVE: "to-approve",
  APPROVING: "approving",
  TO_PLACE: "to-place",
  PLACING: "placing",
  DONE: "done",
};

const PROGRESS_FLOW = [
  PROGRESS.TO_MINT,
  PROGRESS.MINTING,
  PROGRESS.TO_APPROVE,
  PROGRESS.APPROVING,
  PROGRESS.TO_PLACE,
  PROGRESS.PLACING,
  PROGRESS.DONE,
];

export { PROGRESS, PROGRESS_FLOW };
