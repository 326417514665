const WithdrawBalance = {
  inputs: [],
  name: "withdrawBalance",
  outputs: [],
  stateMutability: "nonpayable",
  type: "function",
};

const Abis = {
  WithdrawBalance,
};
export default Abis;
