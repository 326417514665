import { useEffect, useState } from "react";
import BaseViewModel from "../../base/BaseViewModel";
import Web3 from "web3";
import { etherToWei,requiredChainTip,getblockchain} from "../../utils/HelperFunctions";
import {
  createCallbackLog,
  createOffering,
  getOneRequest,
  sendCallback,
  updateOneRequest,
} from "../../utils/Apis";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PROGRESS } from "./constants";
import { useWalletState } from "../../utils/Hooks/useWalletState";
import { useWeb3Handler } from "../../utils/Hooks/useWeb3Handler";

const { ethereum } = window;
const web3 = new Web3(ethereum);

function usePlaceOffering() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    address,
    requestAccounts,
    isChainValid,
    requiredChain,
    currentChain,
  } = useWalletState();
  const { sendTransaction, callMethod } = useWeb3Handler(web3);

  const [isLoading, setIsLoading] = useState(false);
  const [offering, setOffering] = useState({});
  const [request, setRequest] = useState({});
  const [progress, setProgress] = useState(PROGRESS.TO_APPROVE);
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [source, setSource] = useState("");
  const [contract, setContract] = useState({});
  const [tokenContract, setTokenContract] = useState({});

  const fetchRequest = async () => {
    setIsLoading(true);
    try {
      const ref = searchParams.get("ref");
      if (!ref) {
        navigate("/error/?type=invalid-reference", { replace: true });
        return;
      }
      const { message } = await getOneRequest(ref);
      const {
        callbackUrl = "",
        status = "pending",
        offering: originalOffering,
        _id: id,
        transactions,
        externalRef,
        MarketplaceContract = {},
      } = message || {};
      if (status === "success")
        navigate("/success", { state: { source: externalRef }, replace: true });
      setSource(externalRef);
      const { hostContract, contractType } = originalOffering || {};
      const { ApplicableContracts = [] } = MarketplaceContract || {};
      const TokenContract = ApplicableContracts.find(
        ({ type, address }) =>
          type === contractType &&
          address.toLowerCase() === hostContract.toLowerCase()
      );
      setTokenContract(TokenContract);
      setOffering(originalOffering);
      setContract(MarketplaceContract);
      setRequest({ callbackUrl, status, ref, id, transactions });
    } catch (e) {
      console.error(e);
      navigate("/error", { state: { source }, replace: true });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCallback = async (callbackData, requestData) => {
    let status;
    try {
      await updateOneRequest(request.ref, requestData);
      status = "success";
      await sendCallback(request.callbackUrl, callbackData);
      await createCallbackLog({
        Request: request.id,
        status: "success",
        rawData: callbackData,
      });
    } catch (e) {
      await createCallbackLog({
        Request: request.id,
        status: "failed",
        rawData: callbackData,
      });
      console.error(`Failed to update offering: ${e.message}`);

      setError(true);
    } finally {
      if (requestData.status === "success" && status === "success")
        navigate("/success", { state: { source }, replace: true });
    }
  };

  const createOfferingRecord = async (offeringId, type) => {
    try {
      const { message: id } = await createOffering({
        ref: offeringId,
        details: offering,
        type,
        minted: true,
        MarketplaceContract: contract._id,
      });
      return id;
    } catch (e) {
      return null;
    }
  };

  const isApprovedForOne = async () => {
    try {
      const approvedAddress = await callMethod({
        contract: tokenContract,
        methodName: "getApproved",
        inputs: [offering.tokenId],
      });
      return contract.address.toLowerCase() === approvedAddress.toLowerCase();
    } catch (e) {
      return false;
    }
  };

  const isApprovedForAll = async () => {
    try {
      return await callMethod({
        contract: tokenContract,
        methodName: "isApprovedForAll",
        inputs: [address, contract.address],
      });
    } catch (e) {
      return false;
    }
  };

  const isApproved = async () => {
    if (tokenContract.type === "ERC1155") return await isApprovedForAll();
    return await isApprovedForOne();
  };

  const approveMarketplaceForOne = async () => {
    const { tokenId } = offering || {};
    return sendTransaction({
      contract: tokenContract,
      methodName: "approve",
      inputs: [contract.address, tokenId],
      from: address,
      action: "Approve",
    });
  };

  const approveMarketplaceForAll = async () => {
    return sendTransaction({
      contract: tokenContract,
      methodName: "setApprovalForAll",
      inputs: [contract.address, true],
      from: address,
      action: "Approve",
    });
  };

  const approveMarketplace = async () => {
    if (tokenContract.type === "ERC1155")
      return await approveMarketplaceForAll();
    return await approveMarketplaceForOne();
  };

  const processOffering = () => {
    const {
      seller = "",
      hostContract = tokenContract.address,
      contractType = tokenContract.type,
      tokenId = null,
      price = 0,
      closed = false,
      sellType = "fixed",
      royaltyReceiver = seller,
      royaltyBasisPoint = 0,
      serviceFeeBasisPoint = 0,
      supply = 1,
    } = offering || {};

    const newOffering = [
      seller,
      hostContract,
      contractType,
      tokenId,
      etherToWei(price),
      closed,
      sellType,
      royaltyReceiver,
      royaltyBasisPoint,
      serviceFeeBasisPoint,
    ];

    if (contract?.features?.includes("erc-1155")) newOffering.push(supply);

    return newOffering;
  };

  const placeFixedPriceOffering = async () => {
    return sendTransaction({
      contract,
      methodName: "placeFixedPriceOffering",
      inputs: [processOffering()],
      from: address,
      action: "PlaceFixedPriceOffering",
    });
  };

  const placeAuctionOffering = async () => {
    const { startTime = 0, endTime = 0, expirationTime = 0 } = offering || {};
    return sendTransaction({
      contract,
      methodName: "placeAuctionOffering",
      inputs: [processOffering(), startTime, endTime, expirationTime],
      from: address,
      action: "PlaceAuctionOffering",
    });
  };

  const approve = async (transactions) => {
    try {
      setProgress(PROGRESS.APPROVING);

      if (await isApproved()) {
        setProgress(PROGRESS.TO_PLACE);
        await updateOneRequest(request.ref, { status: "approved" });
        return true;
      }

      const approveMarketplaceTxResult = await approveMarketplace();
      transactions.push(approveMarketplaceTxResult);

      if (approveMarketplaceTxResult.status) {
        setProgress(PROGRESS.TO_PLACE);
        await updateOneRequest(request.ref, { status: "approved" });
        return true;
      }

      const response = {
        status: "pending",
        transactions,
      };

      await handleCallback(
        {
          ...response,
          ref: request.ref,
        },
        response
      );

      setError(true);
      setProgress(PROGRESS.TO_APPROVE);
      return false;
    } catch (e) {
      window.alert(e.message);
      setError(true);
      setProgress(PROGRESS.TO_APPROVE);
      return false;
    }
  };

  const placeOffering = async (transactions) => {
    try {
      setProgress(PROGRESS.PLACING);

      const { sellType, seller } = offering;

      if (seller.toLowerCase() !== address?.toLowerCase()) {
        setAlert({
          type: "error",
          title: "Message",
          message: "You are not the seller",
        });
        setProgress(PROGRESS.TO_PLACE);
        setShowAlert(true);
        return;
      }

      let tx;
      if (sellType === "fixed") tx = await placeFixedPriceOffering();
      if (sellType === "auction") tx = await placeAuctionOffering();
      transactions.push(tx);

      if (tx.status) {
        setProgress(PROGRESS.DONE);

        const { receiptRawData } = tx;
        const { logs } = receiptRawData || {};
        const [log] = logs || [];
        const { topics } = log || {};
        const [, offeringId] = topics || [];

        const offeringObjId = await createOfferingRecord(offeringId, sellType);

        const response = {
          status: "success",
          transactions,
        };

        await handleCallback(
          {
            ...response,
            ref: request.ref,
            offeringId: offeringObjId,
          },
          { ...response, Offering: offeringObjId }
        );

        return;
      }

      const response = {
        status: "approved",
        transactions,
      };

      await handleCallback(
        {
          ...response,
          ref: request.ref,
        },
        response
      );

      setError(true);
      setProgress(PROGRESS.TO_PLACE);
    } catch (e) {
      window.alert(e.message);
      setError(true);
      setProgress(PROGRESS.TO_PLACE);
    }
  };

  const onConfirm = async () => {
    try {
      setError(false);
      setIsLoading(true);

      const transactions = [...request.transactions];
      let next = false;

      if (progress === PROGRESS.TO_APPROVE) {
        next = await approve(transactions);
      }

      if (progress === PROGRESS.TO_PLACE || next) {
        await placeOffering(transactions);
      }
    } catch (e) {
      setError(true);
      window.alert(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseAlert = () => {
    setAlert({});
    setShowAlert(false);
  };

  useEffect(() => {
    if (!address) {
      setAlert({
        type: "error",
        title: "Message",
        message: `Please connect to your wallet`,
      });
      setShowAlert(true);
    }
  }, [address]);

  useEffect(() => {
    if (!isChainValid) {
      if(getblockchain()=='Polygon'){
       setShowAlert(false);
        requiredChainTip();
     }else{
      setAlert({
        type: "error",
        title: "Message",
        message: `You are using service provided on ${requiredChain}, but your wallet is connected to ${currentChain}.`,
      });
      setShowAlert(true);
     }
     
    }else{
      if(document.querySelector('.login_tip_dialog')){
        document.querySelector('.login_tip_dialog').remove();
      } 
    }
  }, [isChainValid]);


  useEffect(() => {
    fetchRequest();
  }, []);

  return {
    isLoading,
    progress,
    error,
    alert,
    showAlert,
    address,
    isChainValid,

    onConfirm,
    requestAccounts,
    onCloseAlert,
  };
}

const PlaceOfferingViewModel = BaseViewModel(usePlaceOffering);

export default PlaceOfferingViewModel;
