import Web3 from "web3";

const { ethereum } = window;
const web3 = new Web3(ethereum);
import { CHAIN,CURRDATA,NETPARAME} from "./constants";
const REQUIRED_CHAIN_ID = `0x${parseInt(
  process.env.REACT_APP_CHAIN_ID,
  10
).toString(16)}`;
const REQUIRED_CHAIN = CHAIN[REQUIRED_CHAIN_ID];
const SELECT_CURR = CURRDATA[REQUIRED_CHAIN_ID];
const paramTip =NETPARAME[REQUIRED_CHAIN_ID];

const handleTransaction = async (transactionParameters) => {
  try {
    return await ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
  } catch (e) {
    console.log(e);
    return null;
  }
};

const handleSignedTransaction = async (signedTransactionData) => {
  try {
    let transactionHash;
    await web3.eth
      .sendSignedTransaction(signedTransactionData)
      .once("transactionHash", (hash) => {
        transactionHash = hash;
      });
    return transactionHash;
  } catch (e) {
    console.log(e);
    return null;
  }
};

const getTransactionResult = async (hash, action) => {
  if (!hash) return { status: false };
  try {
    const transaction = await web3.eth.getTransaction(hash);
    let receipt = await web3.eth.getTransactionReceipt(hash);
    while (!receipt) {
      receipt = await web3.eth.getTransactionReceipt(hash);
    }
    let status = false;
    if (receipt) status = receipt.status;

    return {
      status,
      transactionHash: hash,
      block: receipt.blockNumber,
      from: transaction.from,
      to: transaction.to,
      value: transaction.value,
      gasPrice: transaction.gasPrice,
      gasFee: receipt.effectiveGasPrice,
      gasLimit: transaction.gas,
      transactionRawData: transaction,
      receiptRawData: receipt,
      action,
    };
  } catch (e) {
    console.log(e);
    return { status: false, transactionHash: hash, action };
  }
};

const etherToWei = (ether) => {
  return web3.utils.toWei((ether || 0).toString());
};

const etherToHex = (ether) => {
  const amountInWei = etherToWei(ether);
  return web3.utils.numberToHex(amountInWei);
};
//提示
const getblockchain=()=>{
  if(SELECT_CURR=='MATIC'){
    return 'Polygon';
  }else{
    return 'Ethereum';
  }
}
//登录提示
const requiredChainTip=(closecallback)=>{
	let id = new Date().getTime();
	var div = document.createElement("div");
	div.id = id;
	let html="";
  let urls="";
  let tips='<div class="tip_section">'+
  "<p class='tip_titles'>Or you can just try to connect "+paramTip.name+" Network by Chinalist.org simply.</p>"+
  '<p class="step_title">Step-1 :</p>'+
  '<p>Access the <a href="https://chainlist.org/zh/chain/'+process.env.REACT_APP_CHAIN_ID+'"  target="_blank">https://chainlist.org/zh/chain/'+process.env.REACT_APP_CHAIN_ID+'</a>.</p>'+
  '<p class="step_title">Step-2:</p>'+
  '<p>Click Connect Wallet Button to add '+paramTip.name+' Network into your MetaMask.</p>'+
'</div>';
if(paramTip.url){
  urls = '<p>RPC URL: '+paramTip.url+'</p>';
}
  html ='<div class="login_tip_dialog" >'+
	'<div class="close_dialog" id="'+id+'close">'+
	'x'+
	'</div>'+
	'<div class="login_tips">'+
	'<p class="loginp_title">You are going to buy '+paramTip.name+' NFT, please connect '+REQUIRED_CHAIN+' first with following parameters:</p>'+
	'<div class="loginp_content">'+
    '<div class="tip_section">'+
        "<p>Network Name: "+REQUIRED_CHAIN+"</p>"+
        urls+
        '<p>Chain ID: '+process.env.REACT_APP_CHAIN_ID+'</p>'+
        '<p>Currency: '+SELECT_CURR+'</p>'+
      
      '</div>'+tips+'</div>'+
	
	' </div>'+
	'</div>';
	
	div.innerHTML = html;
	document.body.insertBefore(div, document.body.firstElementChild);

	document.getElementById(id+'close').onclick=function(){  
		closecallback&&closecallback(id); 
		document.getElementById(id).remove();
	} 
}

export {
  handleTransaction,
  handleSignedTransaction,
  getTransactionResult,
  etherToWei,
  etherToHex,
  requiredChainTip,
  getblockchain,
};
