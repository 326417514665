import React from "react";
import Button from "../../../components/Button";
import "./style.css";
import Card from "../../../components/Card";
import logo from "../../../asset/logo.png";
const NotFound = () => {
  return (
    <main id={"not-found-page"} className={"wraps"}>
      <div className={"logo_imgs"}>
        <img src={logo} alt="QonBay.io" />
      </div>
      <Card>
        <h2>Page Not Found</h2>
        <p>Sorry, the page you visited does not exist.</p>
        <Button id={"back-btn"} onClick={() => window.close()}>
          Close
        </Button>
      </Card>
    </main>
  );
};

export default NotFound;
