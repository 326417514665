import React, { useContext } from "react";
import WalletViewModel from "../viewModel";
import Button from "../../../components/Button";
import Card from "../../../components/Card";

const ConnectWallet = () => {
  const { requestAccounts, address, onConfirm, isChainValid } = useContext(
    WalletViewModel.Context
  );
  return (
    <Card>
      <h2>Link Wallet Address</h2>
      <Button
        variant={"secondary"}
        onClick={() => requestAccounts()}
        // disabled={!isChainValid}
      >
        Connect Wallet
      </Button>
      <span className={"text"}>Your selected address is:</span>
      <h5 className={"address"}>{address || "N/A"}</h5>
      <Button
        onClick={() => onConfirm(address)}
        // || !isChainValid
        disabled={!address}
        className={"default-btn confirm-btn"}
      >
        Confirm
      </Button>
    </Card>
  );
};

export default ConnectWallet;
