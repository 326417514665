import React from "react";
import "./style.css";

const Card = (props) => {
  const { children, ...rest } = props;
  return (
    <div className={"default-card"} {...rest}>
      {children}
    </div>
  );
};

export default Card;
