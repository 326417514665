import React from "react";
import Button from "../../../components/Button";
import { useLocation, useSearchParams } from "react-router-dom";
import "./style.css";
import Card from "../../../components/Card";
import logo from "../../../asset/logo.png";
const Error = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const type = searchParams.get("type");
  const tips = searchParams.get("tips");
  const { state } = location || {};
  const { source } = state || {};

  const onClickBtn = () => {
    if (source) window.location.replace(source);
    else window.close();
  };

  return (
    <main id={"error-page"} className={"wraps"}>
      <div className={"logo_imgs"}>
        <img src={logo} alt="QonBay.io" />
      </div>
      <Card>
        <h2>Error</h2>
        <p>
          Failed to process your request.
          {tips && <br />}
          {tips && <b>{tips}</b>}
          <br />
          {type === "invalid-reference" && (
            <b>Please provide a valid reference number.</b>
          )}
          {type === "missing-provider" && <b>Please install Metamask.</b>}
        </p>
        <Button id={"back-btn"} onClick={onClickBtn}>
          Back
        </Button>
      </Card>
    </main>
  );
};

export default Error;
