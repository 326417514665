import React, { useContext } from "react";
import CloseOfferingViewModel from "./viewModel";
import Button from "../../components/Button";
import Card from "../../components/Card";
import logo from "../../asset/logo.png";
import "./style.css";
import Processing from "../../components/Status/Processing";
import Failed from "../../components/Status/Failed";
import { Toast, ToastContainer, FormCheck } from "react-bootstrap";
import ErrorList from "../../components/ErrorList";

const CloseOffering = () => {
  const {
    onConfirm,
    isLoading,
    error,
    alert,
    showAlert,
    onCloseAlert,
    handleCheckTerm,
    address,
    isChainValid,
    requestAccounts,
    offering,
  } = useContext(CloseOfferingViewModel.Context);
  return (
    <main id={"close-offering"} className={"wraps"}>
      <ToastContainer position="top-end" className="p-3">
        {showAlert && (
          <Toast onClose={() => onCloseAlert()} delay={5000} autohide>
            <Toast.Header>
              <strong className="me-auto">{alert.title}</strong>
            </Toast.Header>
            <Toast.Body>{alert.message}</Toast.Body>
          </Toast>
        )}
      </ToastContainer>
      <div className={"logo_imgs"}>
        <img src={logo} alt="QonBay.io" />
      </div>
      <Card>
        {offering.type === "auction" ? (
          <h2>Accept Highest Bid</h2>
        ) : (
          <h2>Proceed to Checkout</h2>
        )}
        {!address && (
          <Button
            variant={"secondary"}
            onClick={() => requestAccounts()}
            disabled={!isChainValid}
            style={{ marginBottom: "1rem" }}
          >
            Connect Wallet
          </Button>
        )}
        {isLoading && <Processing />}
        {!isLoading && error && <Failed />}
        {!isLoading && error && <ErrorList />}
        <div className={"button_boxs"}>
          <div className={"term_boxs"}>
            <FormCheck
              id="formControlsText"
              onChange={(e) => handleCheckTerm(e)}
              type="checkbox"
              label="I agree to QonBay.io's"
            />
            <a
              className={"terms_label"}
              href="https://nft.qonbay.io/#/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
          </div>
          <Button
            onClick={() => onConfirm()}
            disabled={isLoading || !address || !isChainValid}
            className={"default-btn confirm-btn"}
          >
            {error ? "Retry" : "Confirm"}
          </Button>
        </div>
      </Card>
    </main>
  );
};

export default CloseOfferingViewModel.withProvider(CloseOffering);
