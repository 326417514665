import React, { useContext } from "react";
import WithdrawBalanceViewModel from "./viewModel";
import Card from "../../components/Card";
import Processing from "../../components/Status/Processing";
import Failed from "../../components/Status/Failed";
import Button from "../../components/Button";
import logo from "../../asset/logo.png";
import "./style.css";
import { Toast, ToastContainer } from "react-bootstrap";

const WithdrawBalance = () => {
  const {
    onConfirm,
    isLoading,
    error,
    balance,
    alert,
    showAlert,
    onCloseAlert,
    address,
    isChainValid,
    requestAccounts,
    breakdowns,
    onDone,
    hasWithdrawn,
    handledContracts,
    currentCurr,
  } = useContext(WithdrawBalanceViewModel.Context);
  return (
    <main id={"withdraw-balance"} className={"wraps"}>
      <ToastContainer position="top-end" className="p-3">
        {showAlert && (
          <Toast onClose={() => onCloseAlert()} delay={5000} autohide>
            <Toast.Header>
              <strong className="me-auto">{alert.title}</strong>
            </Toast.Header>
            <Toast.Body>{alert.message}</Toast.Body>
          </Toast>
        )}
      </ToastContainer>
      <div className={"logo_imgs"}>
        <img src={logo} alt="QonBay.io" />
      </div>
      <Card>
        <h2>Withdraw Balance</h2>
        {!address && (
          <Button
            variant={"secondary"}
            onClick={() => requestAccounts()}
            disabled={!isChainValid}
            style={{ marginBottom: "1rem" }}
          >
            Connect Wallet
          </Button>
        )}
        {isLoading && <Processing />}
        {!isLoading && error && <Failed />}
        <div className={"balance-wrapper"}>
          <span>Total Balance to be withdrawn:</span>
          <span className={"balance"}>{`${balance || "---"} ${currentCurr||'ETH'}`}</span>
          <span className={"address"}>{`(Address: ${address || "---"})`}</span>
          {Array.isArray(breakdowns) && breakdowns.length > 0 && (
            <div className={"balance-breakdowns"}>
              <span className={"title"}>Breakdowns:</span>
              {breakdowns.map(({ total, contract }) => (
                <div key={contract} className={"breakdown-item"}>
                  <div className={"description"}>
                    <label>Contract Address:</label>
                    <span>{` ${contract}`}</span>
                    <label>Balance:</label>
                    <span>{` ${Number(total).toFixed(10)} ${currentCurr||'ETH'}`}</span>
                  </div>
                  <Button
                    onClick={() => onConfirm(contract)}
                    disabled={
                      isLoading ||
                      !address ||
                      !isChainValid ||
                      total === 0 ||
                      handledContracts.includes(contract)
                    }
                    className={"default-btn confirm-btn withdraw-btn"}
                  >
                    Withdraw
                  </Button>
                </div>
              ))}
            </div>
          )}
        </div>
        {hasWithdrawn && (
          <Button
            onClick={() => onDone()}
            disabled={isLoading || !address || !isChainValid}
            className={"default-btn confirm-btn"}
          >
            Done
          </Button>
        )}
      </Card>
    </main>
  );
};

export default WithdrawBalanceViewModel.withProvider(WithdrawBalance);
