import { useEffect, useState } from "react";
import BaseViewModel from "../../base/BaseViewModel";
import Web3 from "web3";
import {
  createCallbackLog,
  createOffering,
  getOneRequest,
  sendCallback,
  updateOneRequest,
} from "../../utils/Apis";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ethers } from "ethers";
import { TypedDataUtils } from "ethers-eip712";
import { useWalletState } from "../../utils/Hooks/useWalletState";
import {requiredChainTip,getblockchain} from "../../utils/HelperFunctions";

const { ethereum } = window;
const web3 = new Web3(ethereum);

function useSignOffering() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    address,
    requestAccounts,
    isChainValid,
    requiredChain,
    currentChain,
  } = useWalletState();

  const [isLoading, setIsLoading] = useState(false);
  const [voucher, setVoucher] = useState({});
  const [request, setRequest] = useState({});
  const [alert, setAlert] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [source, setSource] = useState("");
  const [contract, setContract] = useState({});

  const processVoucher = (offering, token, marketPlaceContract) => {
    const newVoucher = {
      tokenId: offering.tokenId || token.tokenId,
      price: web3.utils.toWei((offering.price || 0).toString()),
      uri: token.uri || "",
      unboxDate: token.unboxDate || 0,
      blindBoxUri: token.blindBoxUri || "",
      royaltyReceiver: offering.royaltyReceiver || offering.seller,
      royaltyBasisPoint: offering.royaltyBasisPoint || 0,
      serviceFeeBasisPoint: offering.serviceFeeBasisPoint || 0,
      owner: offering.seller,
      hostContract: offering.hostContract,
    };
    if (marketPlaceContract?.features?.includes("erc-1155")) {
      Object.assign(newVoucher, { supply: offering.supply || 0 });
    }
    setVoucher(newVoucher);
  };

  const fetchRequest = async () => {
    setIsLoading(true);
    try {
      const ref = searchParams.get("ref");
      if (!ref) {
        navigate("/error/?type=invalid-reference", { replace: true });
        return;
      }
      const { message } = await getOneRequest(ref);
      const {
        callbackUrl = "",
        status = "pending",
        token = {},
        offering = {},
        _id: id,
        transactions,
        externalRef,
        MarketplaceContract = {},
      } = message || {};
      if (status === "success")
        navigate("/success", { state: { source: externalRef }, replace: true });
      setSource(externalRef);
      if (offering.sellType !== "fixed") {
        window.alert("Invalid type");
        navigate("/error", { state: { source }, replace: true });
        return;
      }
      processVoucher(offering, token, MarketplaceContract);
      setContract(MarketplaceContract);
      setRequest({
        callbackUrl,
        status,
        ref,
        id,
        transactions,
        contractType: offering.contractType,
      });
    } catch (e) {
      console.error(e);
      navigate("/error", { state: { source }, replace: true });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCallback = async (callbackData, requestData) => {
    let status;
    try {
      await updateOneRequest(request.ref, requestData);
      status = "success";
      await sendCallback(request.callbackUrl, callbackData);
      await createCallbackLog({
        Request: request.id,
        status: "success",
        rawData: callbackData,
      });
    } catch (e) {
      await createCallbackLog({
        Request: request.id,
        status: "failed",
        rawData: callbackData,
      });
      console.error(`Failed to update offering: ${e.message}`);
    } finally {
      if (requestData.status === "success" && status === "success")
        navigate("/success", { state: { source }, replace: true });
    }
  };

  const createFixedOffering = async (signature, voucher) => {
    try {
      const { message: id } = await createOffering({
        details: voucher,
        ref: signature,
        type: "fixed",
        minted: false,
        MarketplaceContract: contract._id,
        contractType: request.contractType,
        supply: voucher.supply,
        remaining: voucher.supply,
      });
      return id;
    } catch (e) {
      return null;
    }
  };

  const isValidSigner = async () => {
    try {
      const SmartContract = new web3.eth.Contract(
        contract.abi,
        contract.address
      );

      const signerAddress = await SmartContract.methods
        .signer()
        .call({ from: address });

      return signerAddress.toLowerCase() === address.toLowerCase();
    } catch (e) {
      return false;
    }
  };

  const generateDigestV1 = () => {
    const domain = {
      name: "TokenVoucher",
      version: "1",
      chainId: process.env.REACT_APP_CHAIN_ID,
      verifyingContract: contract.address,
    };

    const types = {
      EIP712Domain: [
        { name: "name", type: "string" },
        { name: "version", type: "string" },
        { name: "chainId", type: "uint256" },
        { name: "verifyingContract", type: "address" },
      ],
      Voucher: [
        { name: "tokenId", type: "uint256" },
        { name: "price", type: "uint256" },
        { name: "uri", type: "string" },
        { name: "unboxDate", type: "uint256" },
        { name: "blindBoxUri", type: "string" },
        { name: "royaltyReceiver", type: "address" },
        { name: "royaltyBasisPoint", type: "uint96" },
        { name: "serviceFeeBasisPoint", type: "uint96" },
        { name: "owner", type: "address" },
        { name: "hostContract", type: "address" },
      ],
    };

    return TypedDataUtils.encodeDigest({
      domain,
      types,
      primaryType: "Voucher",
      message: voucher,
    });
  };

  const generateDigestV2 = () => {
    const domain = {
      name: "TokenVoucher",
      version: "1",
      chainId: process.env.REACT_APP_CHAIN_ID,
      verifyingContract: contract.address,
    };

    const types = {
      EIP712Domain: [
        { name: "name", type: "string" },
        { name: "version", type: "string" },
        { name: "chainId", type: "uint256" },
        { name: "verifyingContract", type: "address" },
      ],
      Voucher: [
        { name: "tokenId", type: "uint256" },
        { name: "price", type: "uint256" },
        { name: "uri", type: "string" },
        { name: "unboxDate", type: "uint256" },
        { name: "blindBoxUri", type: "string" },
        { name: "royaltyReceiver", type: "address" },
        { name: "royaltyBasisPoint", type: "uint96" },
        { name: "serviceFeeBasisPoint", type: "uint96" },
        { name: "owner", type: "address" },
        { name: "hostContract", type: "address" },
        { name: "supply", type: "uint256" },
      ],
    };

    return TypedDataUtils.encodeDigest({
      domain,
      types,
      primaryType: "Voucher",
      message: voucher,
    });
  };

  const generateDigest = () => {
    if (contract?.features?.includes("erc-1155")) return generateDigestV2();
    return generateDigestV1();
  };

  const onConfirm = async () => {
    try {
      setIsLoading(true);

      const skipOwnerChecking = contract?.features?.includes(
        "sign-voucher-by-agent"
      );
      const isSigner = await isValidSigner();
      const isOwner = address.toLowerCase() === voucher.owner.toLowerCase();

      if (skipOwnerChecking && !isSigner && !isOwner) {
        setAlert({
          type: "error",
          title: "Message",
          message: `Buyer wallet address not match`,
        });
        setShowAlert(true);
        return;
      }

      if (!skipOwnerChecking && !isOwner) {
        window.alert(
          "Your browser MateMask wallet account does not match your connected wallet, please check your MeteMask and switch to your bound wallet."
        );
        return;
      }

      const provider = new ethers.providers.Web3Provider(ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = await provider.getSigner();
      const digest = generateDigest();
      const signature = await signer.signMessage(digest);
      const offeringId = await createFixedOffering(signature, voucher);

      const response = {
        status: offeringId ? "success" : "failed",
      };

      await handleCallback(
        {
          ...response,
          ref: request.ref,
          offeringId,
        },
        { ...response, Offering: offeringId }
      );
    } catch (e) {
      window.alert(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseAlert = () => {
    setAlert({});
    setShowAlert(false);
  };

  useEffect(() => {
    if (!address) {
      setAlert({
        type: "error",
        title: "Message",
        message: `Please connect to your wallet`,
      });
      setShowAlert(true);
    }
  }, [address]);

  useEffect(() => {
    if (!isChainValid) {
      if(getblockchain()=='Polygon'){
        setShowAlert(false);
        requiredChainTip();
     }else{
      setAlert({
        type: "error",
        title: "Message",
        message: `You are using service provided on ${requiredChain}, but your wallet is connected to ${currentChain}.`,
      });
      setShowAlert(true);
     }
     
    }else{
      if(document.querySelector('.login_tip_dialog')){
        document.querySelector('.login_tip_dialog').remove();
      } 
    }
  }, [isChainValid]);

  useEffect(() => {
    fetchRequest();
  }, []);

  return {
    isLoading,
    alert,
    showAlert,
    address,
    isChainValid,

    onConfirm,
    onCloseAlert,
    requestAccounts,
  };
}

const SignOfferingViewModel = BaseViewModel(useSignOffering);

export default SignOfferingViewModel;
