import React from "react";
import "./style.css";

const Button = (props) => {
  const { children, variant, ...rest } = props;

  switch (variant) {
    case "secondary":
      return (
        <button className={"secondary-btn"} {...rest}>
          <span>{children}</span>
        </button>
      );
    case "default":
    default:
      return (
        <button className={"default-btn"} {...rest}>
          <span>{children}</span>
        </button>
      );
  }
};

export default Button;
