import React, { useContext } from "react";
import SignOfferingViewModel from "./viewModel";
import Card from "../../components/Card";
import "./style.css";
import Button from "../../components/Button";
import { Toast, ToastContainer } from "react-bootstrap";
import logo from '../../asset/logo.png'

const SignOffering = () => {
  const {
    onConfirm,
    isLoading,
    alert,
    showAlert,
    onCloseAlert,
    address,
    isChainValid,
    requestAccounts,
  } = useContext(SignOfferingViewModel.Context);
  return (
    <main id={"sign-offering"} className={'wraps'}>
      <ToastContainer position="top-end" className="p-3">
        {showAlert && (
          <Toast onClose={() => onCloseAlert()} delay={5000} autohide>
            <Toast.Header>
              <strong className="me-auto">{alert.title}</strong>
            </Toast.Header>
            <Toast.Body>{alert.message}</Toast.Body>
          </Toast>
        )}
      </ToastContainer>
      <Card>
      <div className={'logo_imgs'}>
          <img src={logo} alt="QonBay.io"/>
      </div>
        <h2>Sign Offering</h2>
        {!address && (
          <Button
            variant={"secondary"}
            onClick={() => requestAccounts()}
            disabled={!isChainValid}
            style={{ marginBottom: "1rem" }}
          >
            Connect Wallet
          </Button>
        )}
        <p>Please sign the encoded offering details</p>
        <Button
          onClick={() => onConfirm()}
          className={"default-btn confirm-btn"}
          disabled={isLoading || !address || !isChainValid}
        >
          Confirm
        </Button>
      </Card>
    </main>
  );
};

export default SignOfferingViewModel.withProvider(SignOffering);
