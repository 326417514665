import { useEffect, useState } from "react";
import BaseViewModel from "../../base/BaseViewModel";
import {
  createCallbackLog,
  getOneRequest,
  sendCallback,
  updateOneRequest,
} from "../../utils/Apis";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useWalletState } from "../../utils/Hooks/useWalletState";

function useWallet() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    address,
    requestAccounts,
    isChainValid,
    requiredChain,
    currentChain,
  } = useWalletState();

  const [isLoading, setIsLoading] = useState(false);
  const [request, setRequest] = useState({});
  const [alert, setAlert] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [source, setSource] = useState("");

  const fetchRequest = async () => {
    setIsLoading(true);
    try {
      const ref = searchParams.get("ref");
      if (!ref) {
        navigate("/error/?type=invalid-reference", { replace: true });
        return;
      }
      const { message } = await getOneRequest(ref);
      const {
        callbackUrl = "",
        status = "pending",
        action,
        _id: id,
        externalRef,
      } = message || {};
      if (status === "success")
        navigate("/success", { state: { source: externalRef }, replace: true });
      setSource(externalRef);
      setRequest({ callbackUrl, status, action, ref, id });
    } catch (e) {
      console.error(e);
      navigate("/error", { state: { source }, replace: true });
    } finally {
      setIsLoading(false);
    }
  };

  const onConfirm = async (walletAddress) => {
    setIsLoading(true);
    const callbackData = {
      status: "success",
      walletAddress,
      ref: request.ref,
    };
    let status;
    try {
      await updateOneRequest(request.ref, { status: "success", walletAddress });
      status = "success";
      await sendCallback(request.callbackUrl, callbackData);
      await createCallbackLog({
        Request: request.id,
        status: "success",
        rawData: callbackData,
      });
    } catch (e) {
      await createCallbackLog({
        Request: request.id,
        status: "failed",
        rawData: callbackData,
      });
      console.error(`Failed to update wallet address: ${e.message}`);
    } finally {
      setIsLoading(false);
      let type = "";
      if (walletAddress) {
        type = "connectWallet";
      }
      if (status === "success")
        navigate("/success?type=" + type, { state: { source }, replace: true });
    }
  };

  const onCloseAlert = () => {
    setAlert({});
    setShowAlert(false);
  };

  // useEffect(() => {
  //   if (!isChainValid) {
  //     setAlert({
  //       type: "error",
  //       title: "Error",
  //       message: `You are using service provided on ${requiredChain}, but your wallet is connected to ${currentChain}.`,
  //     });
  //     setShowAlert(true);
  //   }
  // }, [isChainValid]);

  useEffect(() => {
    fetchRequest();
  }, []);

  return {
    isLoading,
    request,
    address,
    isChainValid,
    alert,
    showAlert,

    requestAccounts,
    onConfirm,
    onCloseAlert,
  };
}

const WalletViewModel = BaseViewModel(useWallet);

export default WalletViewModel;
