import React, { useContext } from "react";
import PlaceBidViewModel from "./viewModel";
import Card from "../../components/Card";
import Processing from "../../components/Status/Processing";
import Failed from "../../components/Status/Failed";
import Button from "../../components/Button";
import "./style.css";
import { Toast, ToastContainer } from "react-bootstrap";
import ErrorList from "../../components/ErrorList";

const PlaceBid = () => {
  const {
    onConfirm,
    isLoading,
    error,
    alert,
    showAlert,
    onCloseAlert,
    address,
    isChainValid,
    requestAccounts,
  } = useContext(PlaceBidViewModel.Context);
  return (
    <main id={"withdraw-balance"}>
      <ToastContainer position="top-end" className="p-3">
        {showAlert && (
          <Toast onClose={() => onCloseAlert()} delay={5000} autohide>
            <Toast.Header>
              <strong className="me-auto">{alert.title}</strong>
            </Toast.Header>
            <Toast.Body>{alert.message}</Toast.Body>
          </Toast>
        )}
      </ToastContainer>
      <Card>
        <h2>Place Bid</h2>
        {!address && (
          <Button
            variant={"secondary"}
            onClick={() => requestAccounts()}
            disabled={!isChainValid}
            style={{ marginBottom: "1rem" }}
          >
            Connect Wallet
          </Button>
        )}
        {isLoading && <Processing />}
        {!isLoading && error && <Failed />}
        {!isLoading && error && <ErrorList isAuction={true} />}
        <Button
          onClick={() => onConfirm()}
          disabled={isLoading || !address || !isChainValid}
          className={"default-btn confirm-btn"}
        >
          Confirm
        </Button>
      </Card>
    </main>
  );
};

export default PlaceBidViewModel.withProvider(PlaceBid);
