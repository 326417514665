import { useEffect, useState } from "react";
import BaseViewModel from "../../base/BaseViewModel";
import Web3 from "web3";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  createCallbackLog,
  getOneRequest,
  sendCallback,
  updateOffering,
  updateOneRequest,
} from "../../utils/Apis";
import { useWalletState } from "../../utils/Hooks/useWalletState";
import { useWeb3Handler } from "../../utils/Hooks/useWeb3Handler";
import {requiredChainTip,getblockchain} from "../../utils/HelperFunctions";
const { ethereum } = window;
const web3 = new Web3(ethereum);

function useCancelOffering() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    address,
    requestAccounts,
    isChainValid,
    requiredChain,
    currentChain,
  } = useWalletState();
  const { sendTransaction } = useWeb3Handler(web3);

  const [isLoading, setIsLoading] = useState(false);
  const [request, setRequest] = useState({});
  const [error, setError] = useState(false);
  const [offering, setOffering] = useState({});
  const [alert, setAlert] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [source, setSource] = useState("");
  const [contract, setContract] = useState({});

  const fetchRequest = async () => {
    setIsLoading(true);
    try {
      const ref = searchParams.get("ref");
      if (!ref) {
        navigate("/error/?type=invalid-reference", { replace: true });
        return;
      }
      const { message } = await getOneRequest(ref);
      const {
        callbackUrl = "",
        status = "pending",
        Offering = {},
        _id: id,
        transactions = [],
        externalRef,
      } = message || {};
      if (status === "success")
        navigate("/success", { state: { source: externalRef }, replace: true });
      setSource(externalRef);
      if (Offering.status !== "pending") {
        window.alert("Invalid offering");
        navigate("/error", { state: { source }, replace: true });
        return;
      }
      const { MarketplaceContract } = Offering || {};
      setContract(MarketplaceContract);
      setOffering(Offering);
      setRequest({
        callbackUrl,
        status,
        ref,
        id,
        transactions,
      });
    } catch (e) {
      console.error(e);
      navigate("/error", { state: { source }, replace: true });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCallback = async (callbackData, requestData) => {
    setIsLoading(true);
    let status;
    try {
      await updateOneRequest(request.ref, requestData);
      status = "success";
      await sendCallback(request.callbackUrl, callbackData);
      await createCallbackLog({
        Request: request.id,
        status: "success",
        rawData: callbackData,
      });
    } catch (e) {
      await createCallbackLog({
        Request: request.id,
        status: "failed",
        rawData: callbackData,
      });
      console.error(`Failed to update offering: ${e.message}`);
    } finally {
      setIsLoading(false);
      if (requestData.status === "success" && status === "success")
        navigate("/success", { state: { source }, replace: true });
    }
  };

  const cancelFixedPriceOffering = async () => {
    const { ref: offeringId } = offering || {};
    return sendTransaction({
      contract,
      methodName: "cancelFixedPriceOffering",
      inputs: [offeringId],
      from: address,
      action: "CancelFixedPriceOffering",
    });
  };

  const cancelAuctionOffering = async () => {
    const { ref: offeringId } = offering || {};
    return sendTransaction({
      contract,
      methodName: "cancelAuctionOffering",
      inputs: [offeringId],
      from: address,
      action: "CancelAuctionOffering",
    });
  };

  const cancelOffChainOffering = async () => {
    try {
      if (address.toLowerCase() !== offering?.details?.owner.toLowerCase()) {
        setError(true);
        window.alert(
          "Your browser MateMask wallet account does not match your connected wallet, please check your MeteMask and switch to your bound wallet."
        );
        return;
      }

      const { _id: offeringId } = offering || {};
      await updateOffering(offeringId, { status: "cancelled" });

      const response = { status: "success" };
      await handleCallback(
        {
          ...response,
          ref: request.ref,
        },
        response
      );
    } catch (e) {
      const response = { status: "failed" };
      await handleCallback(
        {
          ...response,
          ref: request.ref,
        },
        response
      );
    }
  };

  const cancelOnChainOffering = async (sellType) => {
    try {
      if (address.toLowerCase() !== offering?.details?.seller.toLowerCase()) {
        setError(true);
        window.alert(
          "Your browser MateMask wallet account does not match your connected wallet, please check your MeteMask and switch to your bound wallet."
        );
        return;
      }

      const transactions = [...request.transactions];

      let tx;
      if (sellType === "fixed") tx = await cancelFixedPriceOffering();
      if (sellType === "auction") tx = await cancelAuctionOffering();

      transactions.push(tx);

      if (!tx.status) setError(true);

      const response = {
        status: tx.status ? "success" : "failed",
        transactions,
      };

      await handleCallback(
        {
          ...response,
          ref: request.ref,
        },
        response
      );
    } catch (e) {
      const response = { status: "failed" };
      await handleCallback(
        {
          ...response,
          ref: request.ref,
        },
        response
      );
    }
  };

  const onConfirm = async () => {
    if (request.status === "success") return;

    try {
      setError(false);
      setIsLoading(true);

      const { type, minted } = offering;
      if (minted === false) {
        await cancelOffChainOffering();
      }

      if (minted === true) {
        await cancelOnChainOffering(type);
      }
    } catch (e) {
      setError(true);
      window.alert(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseAlert = () => {
    setAlert({});
    setShowAlert(false);
  };

  useEffect(() => {
    if (!address) {
      setAlert({
        type: "error",
        title: "Message",
        message: `Please connect to your wallet`,
      });
      setShowAlert(true);
    }
  }, [address]);

  useEffect(() => {
    if (!isChainValid) {
      if(getblockchain()=='Polygon'){
        setShowAlert(false);
        requiredChainTip();
     }else{
      setAlert({
        type: "error",
        title: "Message",
        message: `You are using service provided on ${requiredChain}, but your wallet is connected to ${currentChain}.`,
      });
      setShowAlert(true);
     }
     
    }else{
      if(document.querySelector('.login_tip_dialog')){
        document.querySelector('.login_tip_dialog').remove();
      } 
    }
  }, [isChainValid]);

  useEffect(() => {
    fetchRequest();
  }, []);

  return {
    isLoading,
    error,
    alert,
    showAlert,
    address,
    isChainValid,

    onConfirm,
    onCloseAlert,
    requestAccounts,
  };
}

const CancelOfferingViewModel = BaseViewModel(useCancelOffering);

export default CancelOfferingViewModel;
